<template>
  <div :style="`background: #1A1B22`" class="h-100 ">
    <b-row class="no-gutters">
      <b-col cols="12" xl="8" lg="7" class="d-lg-block d-none">
        <div class="background-custom d-flex justify-content-center align-items-center"
          :style="`background-image: url('${imgUrl}');`">

          <b-img class="login-logo" :src="appLogoImage" alt="logo" />

        </div>
      </b-col>
      <b-col cols="12" xl="4" lg="5">
        <div class="min-vh-100 pt-3 pb-5 bg-white h-100 d-flex justify-content-center align-items-center">
          <div :style="`width: 100%; padding: 0 40px`">
            <img src="/logo.png" alt="Tecnitex" class="d-lg-none d-block p-md-3 pb-2" width="100%">

            <h2 class="h2Login">{{ $t('Inicio de sesion') }}</h2>
            <h3 class="h3Login">{{ $t('Plataforma de mantenimiento') }}</h3>
            <span class="linea_naranja"></span>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit" >
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="login-email" placeholder="john@example.com" />
                    <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>

                  <label for="login-password">{{ $t('Contraseña')}}</label>

                  <b-link class="float-right " id="show-btn click" @click="$bvModal.show('forgot-password')">
                    <small>{{ $t('Recuperar contraseña') }}</small>
                  </b-link>
                  <validation-provider #default="{ errors }" name="Password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        placeholder="············" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="d-flex justify-content-between mb-2">
                  <b-col>
                    <b-form-checkbox v-model="rememberme" :checked="false" name="check-remember-button" inline>
                      <span class="">{{ $t('Recordar contraseña') }}</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <!-- submit buttons -->
                <b-button type="submit" variant="primary" class="btn-block">
                  Login
                </b-button>
                <!-- recover password -->
                <b-row>
                  <b-col>


                    <b-modal id="forgot-password" :hide-footer="true" size="lg">
                      <validation-observer ref="forgotPassword">
                        <b-card-title title-tag="h1" class="font-weight-bolder mb-1 text-center">
                          {{ $t('Restablecer contraseña') }}
                        </b-card-title>
                        <div class="asignar-trabajos-modal">
                          <b-row class="justify-content-center middle" align-v="center">
                            <b-col class="text-center" lg="10">
                              <span>{{$t('RestablecerContraseñaDescripcion')}}</span>
                            </b-col>
                          </b-row>

                          <b-form class="mt-2" @submit.prevent="handleSubmitForgotPassword">
                            <b-container class="pl-3 pr-3">
                              <b-row class="justify-content-center middle" align-v="center">
                                <b-col lg="8">
                                  <validation-provider #default="{ errors }" :name="'Email'" rules="required">
                                    <b-form-group :label="'Email'" label-for="forgot-email">
                                      <b-form-input id="login-email" v-model="userEmailForgot"
                                        :state="errors.length > 0 ? false : null" name="login-email-forgot"
                                        placeholder="john@example.com" />
                                    </b-form-group>
                                    <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row class="text-center mb-3">
                                <b-col>
                                  <b-button type="submit" variant="primary" class="mt-1 mr-1 text-uppercase">
                                    {{ $t('Enviar') }}
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-form>
                        </div>
                      </validation-observer>
                    </b-modal>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="mt-2 text-center">
                    <p class="m-0"><small>¿{{ $t('Eres nuevo en la plataforma') }}?</small></p>
                    <b-link :to="{ name: 'register' }">
                      {{ $t('Registrarse') }}
                    </b-link>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-container class="auth-wrapper-3" hidden>
      <b-row class="justify-content-center middle" align-v="center">
        <b-col lg="10" class="d-flex auth-bg px-2 p-lg-5 background-white">
          <b-col sm="12" md="6" lg="6" class="px-xl-2">
            <b-card-title title-tag="h1" class="font-weight-bolder mb-1 text-white">
              {{ $t('Inicio de sesion') }}
            </b-card-title>
            <div style="width: 25%">
              <hr class="linea">
            </div>

          </b-col>
          <b-col sm="12" md="6" lg="6" class="px-xl-2 mx-auto">

          </b-col>
        </b-col>
      </b-row>
    </b-container>

  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BModal, BLink, BImg, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BForm, BButton, BContainer,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import {mapActions, mapMutations} from 'vuex'
import { $themeConfig } from '../../themeConfig'
import config from "@/store/config";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BContainer,
    BFormCheckbox,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      userEmailForgot: '',
      sideImg: require('@/assets/images/pages/login/hall.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      rememberme: false,
      showForgotPassword: false,
      recoverButtonTimeout: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login/login.png')
        this.sideImg = require('@/assets/images/pages/login/background.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      recoverPassword: 'auth/recoverPassword',
    }),
    ...mapMutations({
      setCurrentLanguage: 'languages/setCurrentLanguage',
    }),
    handleSubmit() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const { userEmail, password, rememberme } = this
          if (userEmail && password) {
            this.login({ email: userEmail, password, rememberme })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    handleSubmitForgotPassword() {
      if (this.recoverButtonTimeout) {
        clearTimeout(this.recoverButtonTimeout)
      }

      this.recoverButtonTimeout = setTimeout(() => {
        this.$refs.forgotPassword.validate().then(success => {
          console.log('hola')
          if (success) {
            const { userEmailForgot } = this
            if (userEmailForgot) {
              this.recoverPassword({ email: userEmailForgot })
            }
          }
        })
      }, 500)
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
